* {
  margin: 0;
  padding: 0;
}

:root {
  --base-spacing: 4px;
}

.full {
  width: 100%;
  height: 100%;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.m {
  margin: var(--base-spacing);
}

.m2 {
  margin: calc(var(--base-spacing) * 2);
}

.m3 {
  margin: calc(var(--base-spacing) * 4);
}

.m4 {
  margin: calc(var(--base-spacing) * 6);
}

.m5 {
  margin: calc(var(--base-spacing) * 8);
}

.m6 {
  margin: calc(var(--base-spacing) * 10);
}

.m7 {
  margin: calc(var(--base-spacing) * 12);
}

.m8 {
  margin: calc(var(--base-spacing) * 14);
}

.m9 {
  margin: calc(var(--base-spacing) * 16);
}

.m10 {
  margin: calc(var(--base-spacing) * 18);
}

.p {
  padding: var(--base-spacing);
}

.p2 {
  padding: calc(var(--base-spacing) * 2);
}

.p3 {
  padding: calc(var(--base-spacing) * 4);
}

.p4 {
  padding: calc(var(--base-spacing) * 6);
}

.p5 {
  padding: calc(var(--base-spacing) * 8);
}

.p6 {
  padding: calc(var(--base-spacing) * 10);
}

.p7 {
  padding: calc(var(--base-spacing) * 12);
}

.p8 {
  padding: calc(var(--base-spacing) * 14);
}

.p9 {
  padding: calc(var(--base-spacing) * 16);
}

.p10 {
  padding: calc(var(--base-spacing) * 18);
}

/*# sourceMappingURL=index.769ffc91.css.map */
